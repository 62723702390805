import React from 'react'
import PaymentConfirmation from '../components/PaymentConfirmation'
import Layout from '../components/Layout'

export default () => (
  <Layout themeType="dark" currentPage={'payment-confirmation'}>
    <div>
      <PaymentConfirmation />
    </div>
  </Layout>
)
