import styled from 'styled-components'
import media from '../../../styles/media'

export const StyledContainer = styled.div`
  width: 100%;
  padding: 2rem 0;

  .half-bgr {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: -1;
    img {
      max-height: 300px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .back-btn {
    color: #c59d5f;
    height: 16px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.08px;
    cursor: pointer;
  }

  .purchase-title {
    width: 390px;
    height: 35px;
    font-family: DroidSerif;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    margin: 25px 0 10px;
    color: #ffffff;
  }

  ${media.sm`
    .back-btn {
      margin: 40px 0 0;
    }
  `}

  ${media.md`
    .back-btn {
      margin: 75px 0 0;
    }
  `}

  @media screen and (max-width: 767px) {
    .half-bgr {
      height: 130px;
      img {
        height: inherit;
      }
    }

    .back-btn {
      margin-top: -5px;
    }

    .purchase-title {
      margin: 1.5rem 0;
      font-size: 20px;
      width: auto;
    }
  }
`
