import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { PaymentConfirmationSection } from './style'
import AuthContext from '../../context/auth-context'
import PurchaseLayout from '../Layout/Purchase'
import Check from '../../assets/images/general/check.jpg'
import Button from '../Button'
import { withPrefix } from 'gatsby'
import Loading from '../Loading'
import { EventAction } from '../../helpers/GTM/EventAction'
import { GtmEventsLoad } from '../../helpers/gtm'

class PaymentConfirmation extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const { purchase } = this.context
    if (!purchase) {
      navigate(withPrefix('/events'))
      return
    }

    this.setState({ loading: false })
  }

  componentWillUnmount() {
    const { setPurchase } = this.context
    setPurchase(null)
  }

  formattedNumber = (numberValue: any, isTotal: boolean) => {
    return (
      <div className={`item-prop col sub-value ${isTotal} ? 'total value' : ''`}>
        $ {numberValue ? Number(numberValue).toFixed(2).replace('.', ',') : '0,00'}
      </div>
    )
  }

  generateDynamicIdForGTM = (status: string): string => {
    switch (status) {
      case 'APPROVED':
        return 'GTABLE_EVENTPAY_APPROVED'
      case 'PENDING':
        return 'GTABLE_EVENTPAY_PENDING'
      case 'REJECTED':
        return 'GTABLE_EVENTPAY_REJECTED'
      default:
        return ''
    }
  }

  render() {
    const { purchase } = this.context
    const { loading } = this.state
    if (!purchase) {
      navigate(withPrefix('/events'))
      return <></>
    }
    const isApproved = purchase.transaction.status == 'APPROVED'
    const isPending = purchase.transaction.status == 'PENDING'
    isApproved && GtmEventsLoad(new EventAction('approved', 'purchase', 'event').initialize({eventData: purchase, quantity: purchase.items.length, purchaseData: purchase}))

    return loading === true ? (
      <Loading />
    ) : (
      <PurchaseLayout title="Pago">
        <PaymentConfirmationSection id={this.generateDynamicIdForGTM(purchase.transaction.status)}>
          <div className="purchases-container box">
            <div>
              <img src={Check} />
            </div>
            <div className="success-lbl">
              Tu transacción No: {purchase.transaction.id}
              {isApproved
                ? ' ha sido aprobada.'
                : isPending
                ? ' se encuentra pendiente.'
                : ' ha sido rechazada.'}
            </div>
            <div className="instructions-lbl">
              {isApproved || isPending
                ? 'Pronto recibirás la factura con el resumen de tu transacción al correo registrado anteriormente'
                : 'Tu pago no pudo ser procesado. Por favor revisa tu forma de pago.'}
            </div>
            <div className="next-btn">
              <Button id="next-button" link="events" text="Seguir comprando" />
            </div>
          </div>

          <div className="totals">
            <div className="box">
              <div className="total-row">
                <div className="title row">RESUMEN DE LA COMPRA</div>
              </div>
              <hr style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
              {purchase.items && purchase.items.length > 0
                ? purchase.items.map((purchaseItem: any, index: any) => {
                    return (
                      <div key={index}>
                        <div className="total-row bottom-padding">
                          <div className="item-prop col">{purchaseItem.description}</div>
                          {this.formattedNumber(
                            purchase.purchased_wit_gt
                              ? purchaseItem.grande_table_subtotal
                              : purchaseItem.subtotal,
                            false
                          )}
                        </div>
                        <hr style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
                      </div>
                    )
                  })
                : ''}
              <div className="total-row bottom-padding">
                <div className="item-prop col">I.V.A.</div>
                {this.formattedNumber(
                  purchase.purchased_wit_gt ? purchase.grande_table_tax : purchase.tax,
                  false
                )}
              </div>
              <hr style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
              <div className="total-row">
                <div className="title item-prop col total label">TOTAL</div>
                {this.formattedNumber(
                  purchase.purchased_wit_gt ? purchase.grande_table_total : purchase.total,
                  true
                )}
              </div>
            </div>
          </div>
        </PaymentConfirmationSection>
      </PurchaseLayout>
    )
  }
}
PaymentConfirmation.contextType = AuthContext

export default PaymentConfirmation
