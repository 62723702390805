import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'

export const PaymentConfirmationSection = styled.div`
  display: flex;

  .box {
    margin-top: 2rem;
    padding: 1rem 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
  }

  .purchases-container {
    min-width: 850px;
    margin-right: 1rem;
    padding: 3.5rem;
    text-align: center;

    img {
      width: 144px;
      padding-bottom: 1.5rem;
    }
    .success-lbl {
      height: 30px;
      font-family: Roboto;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: ${theme.purpleBrown};
      margin-bottom: 1rem;
    }
    .instructions-lbl {
      font-family: Roboto;
      font-size: 13px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: ${theme.purpleBrown};
      margin-bottom: 1rem;
    }
  }

  .totals {
    height: 100%;
    width: 100%;
    margin-left: 1rem;

    .total-row {
      display: flex;

      &.bottom-padding {
        padding-bottom: 1rem;
      }
    }

    .next-btn {
      margin: 1rem 0 1.5rem;
      width: 100%;
      #next-button {
        width: 100%;
      }
    }
  }

  .title {
    height: 20px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
    text-transform: uppercase;

    &.col {
      padding: 0 1rem 0 2rem;
    }

    &.row {
      margin: 1.5rem 1.5rem 1rem;
    }
  }

  .item-prop {
    min-height: 40px;
    width: 119px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;

    &.col {
      padding-top: 1rem;
      padding-left: 1.5rem;
    }

    &.sub-value {
      padding-top: 1rem;
      padding-right: 1.5rem;
      text-align: right;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 1.08px;
    }

    &.row {
      margin-top: 1.5rem;
      margin-left: auto;
      margin-right: 1.5rem;
    }
  }

  .total {
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #c59d5f;

    &.label {
      line-height: 1.11;
      letter-spacing: normal;
    }
    &.value {
      line-height: normal;
      letter-spacing: 1.38px;
    }
  }

  ${media.sm`
    .purchases-container {
      min-width: 60%;
    }
  `}

  ${media.md`
    .box {
      margin-top: 3rem;
    }
  `}

  @media screen and (max-width: 767px) {
    flex-direction: column;

    .box {
      padding: 0 0.5rem;
    }
    
    .purchases-container {
      min-width: 100%;
      padding: 1.5rem;
    }

    .totals {
      margin-left: 0;
      .box {
        padding-bottom: 1rem;
      }
      .p2p-info {
        img {
          margin-bottom: 0;
        }
      }
    }
  }
`
