import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { StyledContainer } from './style'
import { withPrefix } from 'gatsby'

const background = require('../../../assets/images/pages/bg-cart.png')

interface Props {
  title: any
  children: any
}

class PurchaseLayout extends Component<Props> {
  constructor(props: any) {
    super(props)
  }

  render() {
    const { title, children }: Props = this.props
    return (
      <StyledContainer>
        <div className="half-bgr">
          <img src={background} />
        </div>

        <div className="container">
          <div
            className="back-btn"
            onClick={() => {
              navigate(withPrefix('/events'))
            }}
          >
            <i className="icon-grande-table-13" />
            Comprar más eventos
          </div>

          <div className="purchase-title">{title}</div>
          <div>{children}</div>
        </div>
      </StyledContainer>
    )
  }
}

export default PurchaseLayout
